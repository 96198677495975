export default {
    'header.help': 'Help',
    'header.account': 'Account',
    'header.welcome': 'Welcome To {msg}',
    'header.signOut': 'Sign Out',
    'header.join': 'Join',
    'header.signIn': 'Sign In',
    'header.search': 'Search For Products..',
    'header.login.signIn': 'SIGN IN',
    'header.login.emailOrPhone': 'Email or Phone number',
    'header.login.empty': 'You can\'t leave this empty.',
    'header.login.maximum': 'Maximum length was {msg} character',
    'header.login.minimum': 'Minimum length was {msg} character',
    'header.login.password': 'Password',
    'header.login.forgotPassword': 'Forgot Password',
    'header.login.register': 'REGISTER',
    'header.login.name': 'Enter your first and last name',
    'header.login.conPassword': 'Confirm Password',
    'header.login.passrequired': 'Please input your password!',
}
